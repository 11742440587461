import styled from "styled-components"

export const Container = styled.div `
    height: 40px;
    background-color: #33a6a6;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`